import React, { useState } from 'react';
import { auth, signInWithEmailAndPassword } from './firebase'; // Import Firebase auth and signInWithEmailAndPassword function
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for navigation
import './SignInForm.css'; // Import CSS file for styling

const SignInForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate hook for navigation

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      // Sign in with email and password
      await signInWithEmailAndPassword(auth, email, password);
      // Navigate to the dashboard upon successful sign-in
      navigate('/Dashboard');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="sign-in-container">
    <p className='text-xl font-semibold' >Admin</p>
      <h2 className='text-bold'>Sign In</h2>
      <form onSubmit={handleSignIn} className="sign-in-form">
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button  className="sign-in-btn">Sign In</button>
        {error && <div className="error-message">{error}</div>} {/* Display error message if any */}
      </form>
    </div>
  );
};

export default SignInForm;
