import React, { useState, useRef, useEffect } from 'react';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import './ContactForm.css';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    service: [],
  });

  
  const [showForm, setShowForm] = useState(true); 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = async () => {
    try {
      const response = await axios.post('1000.ANXI0QZFZVPZJ2A2K1RZE9UBKPDTEQ', {
        to: 'itsamaanansari@gmail.com',
        subject: 'New Contact Form Submission',
        body: `Name: ${formData.name}\nPhone Number: ${formData.phoneNumber}\nService: ${formData.service}`,
      });
      console.log('Email sent:', response.data);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleReqCallDataSubmit = async (e) => {
    e.preventDefault();
    const db = getFirestore();
  
    try {
      const docRef = await addDoc(collection(db, 'contactForms'), {
        name: formData.name,
        phoneNumber: formData.phoneNumber,
        service: formData.service,
        timestamp: serverTimestamp(),
      });
      console.log('Document written with ID: ', docRef.id);
      setShowForm(false); 
      sendEmail(); 
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };
  
  const handleClosePopup = () => {
    setShowForm(true); 
    setFormData({ name: '', phoneNumber: '', service: [ ] }); 
  };


  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^\d{10}$/; // Regex pattern for 10-digit number
    return phoneNumberRegex.test(phoneNumber);
  };


  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const selectedCount = formData.service.length;


  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    if (formData.service.includes(value)) {
      setFormData({
        ...formData,
        service: formData.service.filter((item) => item !== value)
      });
    } else {
      setFormData({
        ...formData,
        service: [...formData.service, value]
      });
    }
  };

  const options = [
    {
      category: "Our Services",
      services: [
        "Civil work",
        "Electrical work",
        "Flooring work",
        "Lighting work",
        
      ],
    },

    {
      category: "Cleaning Services",
      services: [
        "Blend cleaning",
        "Curtain leaning",
        "Sofa & Carpet Cleaning",
        "Chimney Sweeping",
        "Window cleaning",
        "Bathroom Cleaning",
        "Water Tank Cleaning",
      ],
    },

    {
      category: "Annual Maintainance",
      services : [
        "Painting",
        "Air Condition(AC)",
        "Washing Machine",
        "Refrigerator",
        "RO",
        "Inverter",

      ]
    }
    // Add other categories and services here
    // Other categories and their services...
    
  ];

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="contact-form-container">
      {showForm && ( 
        <form onSubmit={handleReqCallDataSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number:</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              pattern="[0-9]{10}" // Pattern attribute for HTML5 validation
              title="Please enter a 10-digit phone number"
            />
          </div>
          <div className="form-group">
        <label htmlFor="service">Choose Service:</label>
        <div className="select-box">
          <div className="selected-options">
            {/* {selectedCount === 0 ? <div className="selected-option">Select</div> : <div className="selected-count">{`Selected ${selectedCount}`}</div>} */}
          </div>
          <div className="dropdown" ref={dropdownRef}>
            <button className="dropdown-toggle" onClick={toggleDropdown}>
              {selectedCount === 0 ? "Select" : `Selected ${selectedCount}`}
            </button>
            {showDropdown && (
              <div className="dropdown-menu">
                {options.map((category, index) => (
                  <div key={index}>
                    <div className="option-header">{category.category}</div>
                    {category.services.map((option, index) => (
                      <div key={index} className="option">
                        <input
                          type="checkbox"
                          id={option}
                          value={option}
                          checked={formData.service.includes(option)}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor={option}>{option}</label>
                      </div>
                    ))}
                  </div>
                ))}
                <button className='DropdownBtn' onClick={() => setShowDropdown(false)}>Done</button>
              </div>
            )}
          </div>
        </div>
      </div>

          <button type="submit">Request a Call Back</button>
        </form>
      )}

      {!showForm && ( // Conditionally render success message
        <div className="success-message">
          <h4>Hello</h4>
          <h2> {formData.name}</h2>
          <p>We will give you a call back on {formData.phoneNumber}.</p>
          <button onClick={handleClosePopup}>OK</button>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
