import React, { useState } from 'react';
import './FAQ.css'; // Import CSS file

const FAQ = () => {
  const [faqs, setFaqs] = useState([
    {
      question: 'What services does Buildology offer?',
      answer: 'Buildology offers a range of services including architectural design, house renovation, and construction services based on your city pin code. We specialize in providing comprehensive solutions for all your building needs.'
    },
    
    {
      question: 'How can I request a quote or estimate for my project?',
      answer: 'To request a quote or estimate for your project, you can fill out the contact form on our website or reach out to us directly via email or give us miss call at 9779703112. Our team will reach you and guide you through the process.'
    },

    {
        question: 'What is the process for architectural design services?',
        answer: 'Our architectural design process involves an initial consultation to understand your requirements and vision. We then create conceptual designs, provide revisions based on your feedback, and finalize the detailed architectural plans for your project and we propose correction to what went wrong.'
      },
      {
          question: 'Can you assist with obtaining necessary permits and approvals?',
          answer: 'Currently we do not!'
        },
        {
          question: 'What types of house renovation services do you provide?',
          answer: 'We offer a wide range of house renovation services, including kitchen and bathroom remodeling, interior renovations, flooring and painting, electrical and plumbing upgrades, and more. Our team will work closely with you to transform your space according to your requirements and accomodate the item already with you.'
        },
        {
          question: 'Are your construction services available for residential and commercial projects?',
          answer: 'Yes, our construction services are available for both residential and commercial projects. We have the expertise and resources to handle projects of various scales and complexities.'
        },
        {
          question: 'What is the typical timeline for completing a project?',
          answer: 'The timeline for completing a project can vary depending on the size, scope, and specific requirements. We provide estimated timelines during the project planning phase and strive to deliver projects within agreed-upon timeframes.'
        },
        {
          question: 'Do you offer customizable design options to suit my specific requirements?',
          answer: 'Yes, we understand that each client has unique preferences and requirements. Our design team works closely with you to customize the design according to your specific needs, ensuring that it reflects your vision and style.'
        },
        // {
        //   question: 'Are your services available throughout India or limited to specific regions?',
        //   answer: 'Our services are available throughout India. We have successfully completed projects in various cities and regions, and we are equipped to serve clients across the country.'
        // },
        {
          question: 'What are the qualifications and experience of your architectural and construction team?',
          answer: 'Our architectural and construction team consists of highly skilled and trained professionals with relevant qualifications and extensive industry experience. They are equipped to handle diverse projects and ensure high  quality workmanship.'
        },
        {
          question: 'What payment options do you accept?',
          answer: 'We accept various payment options, including bank transfers, online payments, and cheques. Our team will provide you with detailed information regarding payment terms and methods during the project discussion phase.'
        },
        {
          question: 'Do you provide any post-construction support or warranty?',
          answer: 'Yes, we provide post-construction support and offer warranties on our work. If any issues arise after the completion of the project, our team will address them promptly and ensure your satisfaction'
        },
        {
            question:'Do you provide any Annual Maintenance Contract(AMC)?',
            answer:'Yes,  we undertake AMC for appliances and equipments of your home.'

        }


  ]);

  const [visibleFAQs, setVisibleFAQs] = useState(3);

  const toggleFAQ = index => {
    setFaqs(prevFaqs => 
      prevFaqs.map((faq, i) =>
        i === index ? { ...faq, isOpen: !faq.isOpen } : faq
      )
    );
  };

  const loadMore = () => {
    setVisibleFAQs(prevVisibleFAQs => prevVisibleFAQs + 3);
  };

  return (
    <div  className="faq-container">
      <h1>Frequently Asked Questions</h1>
      {faqs.slice(0, visibleFAQs).map((faq, index) => (
        <div key={index} className="faq">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <p>{faq.question}</p>
            {faq.isOpen ? 
            <span>-</span> : <span>+</span>
            }
          </div>
          {faq.isOpen && <div className="faq-answer">
          <p>{faq.answer}</p>
          </div>}
        </div>
      ))}
      {visibleFAQs < faqs.length && (
        <button className="load-more-btn" onClick={loadMore}>
          Load More
        </button>
      )}
    </div>
  );
};

export default FAQ;
