// HomePage.js
import React from 'react';
import './HomePage.css';
import Landing from './Landing';
import AboutusImg from '../images/building.png';
import ValuesImg from "../images/values.png";
import ReasonsImg from "../images/ReasonsImg.png";
import Footer from './Footer';
import Newsletter from './Newsletter';
import FAQ from './FAQ';

const HomePage = () => {
  return (
    <div className="HomePage-container">
    <div className='ReasonsPage'>
    <Landing/>
    </div>


    <div id='about'  className='AboutUsHeader'>
        <div className='AbtUsHeaderLine-Left'></div>
        <div className='AbtUsHeader-Middle'><h1>About Us</h1></div>
        <div className='AbtUsHeaderLine-Right'></div>

    </div>
    <div className='AboutUsExt'>
        <div className='MissionCont'>
        <div className='MissionContImg'>
        <img className='MissionImg' src={AboutusImg}/>

        </div>
        <div className='VissionText'>
          <h1>Our Vision <br></br> And Mission</h1>
          <p>Inspiring the future of construction, we craft the blueprint for a sustainable world with our groundbreaking building solutions. Our mission is to transform spaces and expectations, merging artistry with innovation. We're not just building structures; we're elevating experiences, ensuring each project suits your style, delivered with passion, on time and on budget. Join us in reimagining the skyline, one innovative solution at a time.</p>
        </div>

        </div>

        <div className='MissionCont'>
        
        <div className='VissionText'>
          <h1>Our Values And Principles</h1>
          <p>At Buildiology, we believe in more than just bricks and mortar. We are passionate about creating spaces that enhance lives and communities. Our core values guide every decision we make, ensuring the quality, integrity, and sustainability of our projects.
          These values are more than just words on a page. They are woven into the fabric of our company culture, shaping how we approach every project.</p>
        </div>

        <div className='MissionContImg'>
        <img className='MissionImg' src={ValuesImg}/>
        </div>
        </div>

    </div>

    <div className='AboutUsBottom'></div>

    <div className='Ouroffer'>
  
    {/* <h1>Our Offer</h1> */}
    {/* <h4>W are offering the plan that will help you build your home brick by by to the roof</h4> */}
    <div className='OurOfferCard'>
    <div className='Features'>
    <div className="Features-container">
      <div className="Features-banner">
        <h1>Our Offer</h1>
        <h3><span>Strategy</span> and <span>Implementation</span> support for <span>CXOs</span> in Indian Market</h3>
      </div>
      <div className="Fcard-container">
        {/* Card 1 */}
        <div className="Fcard">
        <div className='Num'>
        <h1>1.</h1>
        </div>
          <div>
          <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </h3>
          </div>
        </div>
        {/* Card 2 */}
        <div className="Fcard">
        <h1>2.</h1>
          <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </h3>
        </div>
        {/* Card 3 */}
        <div className="Fcard">
        <h1>3.</h1>
          <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </h3>
          
        </div>
      </div>
    </div>

    </div>
    </div>

    </div>


    <div className="Reasons-container">
    <div className='ReasonsText'>
    <h1>Why To Choose Us?</h1>

    </div>

    <div className='ReasonsMiddleCont'>

    <div className='RMiddle-Left'>
      <div className="RCard">
        <div className='Num'>
        <h2>Dedication to Quality</h2>
        </div>
          <div>
          <p>We use the finest & quality materials and employ meticulous construction practices to ensure the longevity and add values to your home</p>
          </div>
        </div>

        <div className="RCard">
        <div className='Num'>
        <h2>Focus on Customer Satisfaction</h2>
        </div>
          <div>
          <p>Your satisfaction is our top priority. We listen attentively to your needs and concerns, working collaboratively to achieve your dream home.</p>
          </div>
        </div>

    </div>
    <div className='ReasonsImgCont'>
    <img className='ReasonsImg' src={ReasonsImg}/>

    </div>

    <div className='RMiddleRight'>
    <div className="RCard">
        <div className='Num'>
        <h2>Detailed Communication</h2>
        </div>
          <div>
          <p>We maintain open communication throughout the project, keeping you informed and involved every stage of the way.</p>
          </div>
        </div>
        <div className="RCard">
        <div className='Num'>
        <h2>Quality Craftsmanship</h2>
        </div>
          <div>
          <p>Our team of skilled professionals equipped with new thechnology are dedicated to deliver exceptional results.</p>
          </div>
        </div>

    

    </div>

    
    </div>
    
    </div>
    <div id='faq' className='FaqCont'>
    <FAQ/>

    </div>
    <Newsletter/>
    
    </div>
  );
};

export default HomePage;
