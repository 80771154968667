import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { IoIosCloseCircleOutline } from "react-icons/io";
import ContactForm from './ContactForm';
import Logo from '../images/Logo.png';


const Navbar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const scrollToAbout = (event) => {
    event.preventDefault(); // Prevent default anchor tag behavior
    const aboutSection = document.getElementById('about');
    if (aboutSection) {
      window.scrollTo({
        top: aboutSection.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <nav className={`navbar ${sidebarOpen ? 'sidebar-open' : ''}`}>
      <Link to='/'>
      <div className="navbar-logo">
        <img className='LogoImg' src={Logo} alt="Logo" />
      </div>
      </Link>

      <div className={`navbar-links ${sidebarOpen ? 'show' : ''}`}>
        <ul>
          <li>
            <Link to="/" exact activeClassName="active">
              Home
            </Link>
          </li>
          <li>
            <Link to="/services" activeClassName="active">
              Services
            </Link>
          </li>
          <li>
            <a href="/#about">
              About Us
            </a>
          </li>
          <li>
            <Link to="/faqs" activeClassName="active">
              FAQs
            </Link>
          </li>
        </ul>
      </div>

      <div className="navbar-contact">
        <button onClick={togglePopup}>Contact Us</button>
      </div>
      <div className="navbar-toggle" onClick={toggleSidebar}>
        <div className="icon-bar"></div>
        <div className="icon-bar"></div>
        <div className="icon-bar"></div>
      </div>

      {showPopup && (
        <div className="Navbar-popup">
          <div className="Navbar-popup-content">
            <ContactForm />
            <IoIosCloseCircleOutline className="popup-close" onClick={togglePopup} />
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
