import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomePage from './components/HomePage';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Footer from './components/Footer';
import RequirementForm from './components/RequirementForm';
import SignInForm from './components/SignInForm';
import Dashboard from './components/Dashboard';
import FaqPage from './components/FaqPage';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar /> {/* Ensure Navbar component renders correctly */}
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/Services" element={<Services />} />
          <Route exact path="/faqs" element={<FaqPage />} />

          <Route exact path="/RequirementForm" element={<RequirementForm />} />
          <Route exact path="/SignInForm" element={<SignInForm />} />
          <Route exact path="/Dashboard" element={<Dashboard />} />


        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
