import React, { useEffect, useState } from 'react';
import { auth, db } from './firebase'; // Import Firebase auth and Firestore db
import './Dashboard.css'; // Import CSS for styling
import ReqData from './ReqData'; // Import ReqData component
import ContFormData from './ContFormData'; // Import ContFormData component

const Dashboard = () => {
  const [contactFormData, setContactFormData] = useState([]);
  const [requirementFormData, setRequirementFormData] = useState([]);

  useEffect(() => {
    // Fetch Contact Form data from Firestore
    const fetchContactFormData = async () => {
      const contactFormRef = db.collection('contactForm');
      const snapshot = await contactFormRef.get();
      const data = snapshot.docs.map((doc) => doc.data());
      setContactFormData(data);
    };

    // Fetch Requirement Form data from Firestore
    const fetchRequirementFormData = async () => {
      const requirementFormRef = db.collection('requirement');
      const snapshot = await requirementFormRef.get();
      const data = snapshot.docs.map((doc) => doc.data());
      setRequirementFormData(data);
    };

    // Check if user is authenticated
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchContactFormData();
        fetchRequirementFormData();
      } else {
        // Redirect user to login page if not authenticated
        // You can implement this based on your routing setup
        console.log('User not authenticated');
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className='DashbCont'>
    <div className="dashboard-container w-96 md:w-full overflow-x-auto">
      <h1 className='text-xl font-bold'>Dashboard</h1>
      <hr></hr>

      <div className="contact-form-data">
        {/* <h3>Contact Form Data</h3> */}
        <ul>
          {contactFormData.map((formData, index) => (
            <li key={index}>{JSON.stringify(formData)}</li>
          ))}
        </ul>
      </div>

      <div className="requirement-form-data">
        {/* <h3>Requirement Form Data</h3> */}
        <ul>
          {requirementFormData.map((formData, index) => (
            <li key={index}>{JSON.stringify(formData)}</li>
          ))}
        </ul>
      </div>

      {/* Render the ReqData and ContFormData components */}
      {/* <ReqData /> */}
      <ContFormData />
    </div>
    </div>
  );
};

export default Dashboard;
