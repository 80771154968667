import React from "react";
import './Services.css';
import ServicesImg from '../images/ServicesImg.png';

import GreenTick from '../images/GreenTick.png';
import OurServicesCard from "./OurServicesCard";
import ReasonsImg from "../images/ReasonsImg.png";
import { Link } from "react-router-dom";
import RequirementForm from "./RequirementForm";
import Newsletter from "./Newsletter";
import PainPoints from "./Painpoints";



function Services(){
    return(
        <div className="ServicePage">
        <div className="ServiceLanding">
        <div className="ServiceText">

            
            <h1>Helping Everyone Build Their Dream Home with Our Experienced Team in Real Estate</h1>
            <div className="ServiceBtn">
            <a href="#services">
            <button className=" ServContBtn">Explore Services </button>
            </a>
        </div>
        </div>
        
        
        </div>

        <div id="services" className="ServicesContList">
        {/* <div className="OurServicesCard">
        <div className="OurServicesImgCont">
        <img className="OurServicesListImg" src={ServicesImg}/>

        </div>
        <div className="ServicesTitle">
        <h3>Modular Kitchen</h3>
        </div>

        </div> */}

        <h1>Our Services</h1>
        <p></p>

        <OurServicesCard/>
         </div>

    <div className="SReasons-container">
    <div className='ReasonsText'>
    <h1>Reasons to Trust Us!</h1>

    </div>

    <div className='ReasonsMiddleCont'>

    <div className='RMiddle-Left'>
      <div className="SRCard">
        <div className='Num'>
        <h2>Dedication to Quality</h2>
        </div>
          <div>
          <p>We use only the finest materials and employ meticulous construction practices to ensure the longevity and value of your home</p>
          </div>
        </div>

        <div className="SRCard">
        <div className='Num'>
        <h2>Focus on Customer Satisfaction</h2>
        </div>
          <div>
          <p> Your satisfaction is our top priority. We listen attentively to your needs and concerns, working collaboratively to achieve your dream home.</p>
          </div>
        </div>

    </div>
    <div className='ReasonsImgCont'>
    <img className='ReasonsImg' src={ReasonsImg}/>

    </div>

    <div className='RMiddleRight'>
    <div className="SRCard">
        <div className='Num'>
        <h2>Detailed Communication</h2>
        </div>
          <div>
          <p> We maintain open communication throughout the project, keeping you informed and involved every step of the way.</p>
          </div>
        </div>
        <div className="SRCard">
        <div className='Num'>
        <h2>Quality Craftsmanship</h2>
        </div>
          <div>
          <p>Our team of skilled builders and tradespeople are dedicated to delivering exceptional results using top-quality materials.</p>
          </div>
        </div>

    

    </div>
    </div>
    </div>
    <div className="PainpointsCont">
    <div className="PCont">
    <div className="PHeader">
        <h1><span className="why">Why</span><br></br>You Need Us?</h1>
        <p>Overcome construction and interior challenges effortlessly with our expert team. From navigating regulations to optimizing space, we provide tailored solutions. Trust us to guide your project from start to finish, delivering exceptional results that exceed expectations and bring your vision to life.</p>
    </div>
      <PainPoints/>
      </div>
    </div>
    <Newsletter/>

        </div>
    );
}

export default Services;