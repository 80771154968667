// OurServicesCard.js
import React from 'react';
import './OurServicesCard.css';
import kitchen from '../images/kitchen.png'
import wardrobe from '../images/wardrobe.png';
import lighting from '../images/lighting.png';
import electrical from '../images/electrical.png';
import floor from '../images/floor.png';
import ceiling from '../images/celing.png';
import painting from '../images/painting.png';
import space from '../images/space.png';
import ArchDesign from "../images/ArchDesign.png";
import Trunkey from "../images/Trunkey.png";
import Commercial from "../images/Commercial.png";
import Cost from "../images/Cost.png";
import Consult from "../images/Consult.png";
import Material from "../images/Material.png";
import Mechanical from "../images/mechanical.png";
import Patner from "../images/patner.png";
import Labor from "../images/Labor.png";
import loan from "../images/loan.jpeg";






const services = [
  // {
  //   id: 1,
  //   name: "Modular Kitchen",
  //   description: "Description of Service 1",
  //   image: kitchen
  // },
  // {
  //   id: 2,
  //   name: "Modular Wardrobe",
  //   description: "Description of Service 2",
  //   image: wardrobe
  // },
  // {
  //   id: 3,
  //   name: "Lighting Works",
  //   description: "Description of Service 3",
  //   image: lighting
  // },

  // {
  //   id: 4,
  //   name: "Electrical Work",
  //   description: "Description of Service 4",
  //   image:electrical
  // },
  // {
  //   id: 3,
  //   name: "Floor Works",
  //   description: "Description of Service 3",
  //   image: floor
  // },
  
  {
    id: 1,
    name: "Architecture Design",
    description: "Get your home architecture designed at an afforadbale rate",
    image: ArchDesign
  },
  {
    id: 2,
    name: "Turnkey Construction",
    description: "End-to-end turnkey construction services: from initial planning to final delivery, we handle every aspect of your project for a hassle-free experience. ",
    image: Trunkey
  },
  {
    id: 3,
    name: "Commercial Construction",
    description: "One stop solution for all your commercial construction needs",
    image: Commercial
  },
  {
    id: 4,
    name: "Budget Control and Cost Monitoring",
    description: "Comprehensive budget control and cost monitoring solutions ensuring value for money",
    image: Cost
  },
  {
    id: 5,
    name: "Engineering Consultation",
    description: "Expert civil engineering consultation: customized solutions for your infrastructure projects, from planning to execution ",
    image: Consult
  },
  {
    id: 6,
    name: "Construction Material",
    description: "Get all construction and furnishing materials at wholesale rate from us",
    image: Material
  },
  {
    id: 7,
    name: "Mechnanical Works",
    description: "Equipped with new technology",
    image: Mechanical
  },
  {
    id: 8,
    name: "Partnership Projects",
    description: "We are the trusted parner for all your needs",
    image: Patner
  },

  {
    id: 9,
    name: "Functional Space Management",
    description: "Optimizing your space usability, ensuring comfort, and functionality through strategic planning and innovative design.",
    image: space
  },
  {
    id: 10,
    name: "Labor Rate Construction",
    description: "Hire Trained Workers for strong structure and foundation",
    image: Labor
  },
  {
    id: 11,
    name: "Loan Services",
    description: "We have tieups with different bank for loan services.",
    image: loan
  },




];

const OurServicesCard = () => {
  return (
    <div className="services-grid">
      {services.map(service => (
        <div key={service.id} className="service-card">
          <img src={service.image} alt={service.name} />
          <h3>{service.name}</h3>
          <p>{service.description}</p>
        </div>
      ))}
    </div>
  );
};

export default OurServicesCard;
