import React, { useState } from 'react';
import { FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa'; // Import social icons
import { IoIosCloseCircleOutline } from "react-icons/io";
import './Footer.css'; // Import the CSS file for styling
import SignInForm from './SignInForm';
import ContactForm from './ContactForm';
import Logo from "../images/BuildologyLogo.png";
import { IoIosCall } from "react-icons/io";
import { CiMail } from "react-icons/ci";


function Footer() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section">
          <div className="company-logo-container">
            {/* Your logo goes here */}
            <img src={Logo} alt="Company Logo" className="company-logo" />
          </div>
          <p className="tagline">Registered Office: Kothi No. 792, Sector 91, Mohali, Punjab </p>
          <p className='tagline'>Office Address: #5580, Block F, Aerocity, SAS Nagar Mohali, Punjab</p>

         
        </div>

        <div className='Divider'></div>




        <div className="footer-section">

        
          {/* <h3>Quick Links</h3> */}
          <ul className="quick-links">
            <li><a href="/">Home</a></li>
            <li><a href="/Services">Services</a></li>
            <li><a href="/faqs">Faq</a></li>
          </ul>

         
          
          
        </div>
        <div className='FooterIcons'>
          
          <div className='FIconsCont'>
          <IoIosCall className='FIcons'/><p> 9815355112 </p>
          </div>
          <div className='FIconsCont'>
          <CiMail className='FIcons'/><p> support@buildology.in </p>
          </div>
          </div>

        {/* <div className="footer-section social-section">
          <h3>Follow Us</h3>
          <ul className="social-icons">
            <li><a href=""><FaTwitter /></a></li>
            <li><a href=""><FaLinkedin /></a></li>
            <li><a href=""><FaInstagram /></a></li>
          </ul>
        </div> */}
      </div>
      <hr></hr>
      <div className='Privacy'>
        <h5>
          © Buildology 
        </h5>

        <a href="./Privacy"><h5 >| Privacy & Policy</h5></a>
        <a href="./Terms"><h5 >| Terms of Use</h5></a>
        <a href="./SignInForm"><h5 >| Admin</h5></a>
      </div>

      {/* Popup component */}
      {showPopup && (
        <div className="Footer-popup">
          <div className="Footer-popup-content">
            {/* Your popup content goes here */}
            <ContactForm />
            <IoIosCloseCircleOutline className="popup-close" onClick={togglePopup} />
          </div>
        </div>
      )}
    </footer>
  );
}

export default Footer;
