import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCr0gZBxwrRHdcwZDxcol7-sEb8Mb2oeh4",
  authDomain: "buildology-91.firebaseapp.com",
  projectId: "buildology-91",
  storageBucket: "buildology-91.appspot.com",
  messagingSenderId: "803247062704",
  appId: "1:803247062704:web:3d54ed074fb57f91708575",
  measurementId: "G-3BM74HKNHC"
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export {
  app,
  auth,
  db,
  storage,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  collection,
  getDocs
};
