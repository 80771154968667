// PainPoints.js
import React from 'react';
import './PainPoints.css';
import kitchen from '../images/kitchen.png'
import wardrobe from '../images/wardrobe.png';
import lighting from '../images/lighting.png';
import electrical from '../images/electrical.png';
import floor from '../images/floor.png';
import ceiling from '../images/celing.png';
import painting from '../images/painting.png';
import space from '../images/SSpace.png';
import ArchDesign from "../images/ArchDesign.png";
import Trunkey from "../images/Trunkey.png";
import Commercial from "../images/Commercial.png";
import Cost from "../images/Cost.png";
import constructionerror from "../images/constructionerror.png";
import pain2 from "../images/pain2.png";
import pain3 from "../images/pain3.png";
import pain4 from "../images/pain4.png";
import Labor from "../images/Labor.png";






const painpoints = [
  // {
  //   id: 1,
  //   name: "Modular Kitchen",
  //   description: "Description of painpoint 1",
  //   image: kitchen
  // },
  // {
  //   id: 2,
  //   name: "Modular Wardrobe",
  //   description: "Description of painpoint 2",
  //   image: wardrobe
  // },
  // {
  //   id: 3,
  //   name: "Lighting Works",
  //   description: "Description of painpoint 3",
  //   image: lighting
  // },

  // {
  //   id: 4,
  //   name: "Electrical Work",
  //   description: "Description of painpoint 4",
  //   image:electrical
  // },
  // {
  //   id: 3,
  //   name: "Floor Works",
  //   description: "Description of painpoint 3",
  //   image: floor
  // },
  
  {
    id: 1,
    name: "Construction Faults",
    image: constructionerror
  },
  {
    id: 2,
    name: "Biggest Mistakes",
    image: pain2
  },
  {
    id: 3,
    name: "Poor Kitchen Designs",
    image: pain3
  },
  {
    id: 4,
    name: "Flooring Mistakes",
    image: pain4
  },
  



];

const PainPoints = () => {
  return (
    <div className="painpoints-grid">
      {painpoints.map(painpoint => (
        <div key={painpoint.id} className="painpoint-card">
          <img src={painpoint.image} alt={painpoint.name} />
          <h3>{painpoint.name}</h3>
          {/* <p>{painpoint.description}</p> */}
        </div>
      ))}
    </div>
  );
};

export default PainPoints;
