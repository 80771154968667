import './Landing.css';
import ContactForm from './ContactForm';
import { IoIosCloseCircleOutline } from "react-icons/io";
import React,{useState} from 'react';

// import Buildology from "../images/Buildology.png";


// import LandingImg from '../images/Landingbg.jpg';

const Landing = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="landing-container ">
    {/* <div className="gradient-shape gradient-shape-1"></div> */}
      {/* <div className="gradient-shape gradient-shape-2"></div> */}
      {/* <div className="gradient-shape gradient-shape-3"></div> */}

      {/* <div className="Buildology">
             
      <img className="BLogo" src={Buildology}/>

      </div> */}
     
    <div className='LandingText'>
    <h1>Define Your World  <br></br>in a Whole New Different Way</h1>
    <button className='LandingBtn1' onClick={togglePopup}>Contact Us</button>
    </div>

    <div className='LandingMiddleCont'>

    <div className='Middle-Left'>
    <div className='Landing-Vid'>

    </div>

    <p>Experienced team to help you build your dream home</p>
    <div className='LandingBtnCont'>
        <button className='LandingBtn'>Contact Us</button>
      </div>
    </div>
    <div className='LandingImgCont'>
    {/* <img className='LandingImg' src={LandingImg}/> */}

    </div>

    <div className='MiddleRight'>
    <div className='ClientsNo'>

    <h1>200+</h1>
    <p>Clients</p>

    </div>

    <div className='ClientsNo'>

    <h1>200+</h1>
    <p>Clients</p>

    </div>

    </div>
    </div>

    {showPopup && (
        <div className="Navbar-popup">
          <div className="Navbar-popup-content">
            <ContactForm />
            <IoIosCloseCircleOutline className="popup-close" onClick={togglePopup} />
          </div>
        </div>
      )}
    
    </div>
  );
};

export default Landing;
