import React, { useState } from 'react';
import './Newsletter.css';
import ContactForm from './ContactForm';
import { IoIosCloseCircleOutline } from "react-icons/io";


function Newsletter(){

    const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

    return(

    <div className='NewsLetter'>
    <div className='NewsLetter-Text'>
    <h2>Reach Out to Get Your Home Engineered by us</h2>
    </div>
    <div className='NewsLetter-btn'>
    <button className='News-contact' onClick={togglePopup}>Contact us</button>

    </div>
    {showPopup && (
        <div className="popup">
          <div className="popup-content">
          <ContactForm />
          <IoIosCloseCircleOutline className="popup-close" onClick={togglePopup} />
          </div>
        </div>
      )}

    </div>

    );
}
export default Newsletter;