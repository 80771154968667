import React from "react";
import './FaqPage.css';
import Faq from '../images/Faq.png';
import FAQ from "./FAQ";


function FaqPage(){
    return(
        <div className="FaqPageMain">
            
            <div className="FaqLanding">
            <div className="Faqimg">
            <img  src={Faq}/>

            </div>

            <div className="FaqLandingtext">
                {/* <h3>FAQ</h3> */}
                <h1>Got Queries!</h1>
                <p> Refer to Frequently Asked Question</p>
                <h4>For specific questions mail us on <a href="mailto: support@buildology.in"><span className="mailtext">support@buildology.in </span> </a></h4>
                <a href="mailto:support@buildology.in">
                <button className="MailtBtn">Wrtie us an email </button>
                </a>
            
            </div>  
            
            </div>

            <FAQ/>


        </div>

    );
}

export default FaqPage;