import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, orderBy, doc, setDoc } from 'firebase/firestore';

const ContFormData = () => {
  const [contData, setContData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const contCollection = collection(db, 'contactForms');
      const q = query(contCollection, orderBy('name', 'asc')); // Sort by name alphabetically
      const snapshot = await getDocs(q);
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setContData(data);
    };

    fetchData();
  }, []);

  const handleStatusChange = async (event, id) => {
    const selectedStatus = event.target.value;
    const db = getFirestore();
    const contactDocRef = doc(db, 'contactForms', id);
    await setDoc(contactDocRef, { status: selectedStatus }, { merge: true }); // Merge with existing data

    // Update the local state to reflect the change
    setContData(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return { ...item, status: selectedStatus };
        }
        return item;
      })
    );
  };

  return (
    <div className="data-container">
      <h2 className='text-xl font-semibold'>Contact Form Data</h2>
      <table className='w-96 md:w-full overflow-x-auto'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Selected Services</th>
            <th>Status</th> {/* Add Status column */}
            {/* <th>Message</th> */}
          </tr>
        </thead>
        <tbody>
          {contData.map(item => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.phoneNumber}</td>
              <td>
                <ul>
                  {Array.isArray(item.service) && item.service.map((service, index) => (
                    <li key={index}>{service}</li>
                  ))}
                </ul>
              </td>
              <td>
                <select
                  value={item.status || 'Pending'} 
                  onChange={event => handleStatusChange(event, item.id)}
                >
                  <option value="Pending">Pending</option>
                  <option value="Contacted">Contacted</option>
                  <option value="Revert requested">Revert requested</option>
                </select>
              </td>
              {/* <td>{item.message}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContFormData;
