import React, { useState } from 'react';
import './RequirementForm.css';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import GreenTick from '../images/tick.png';


const RequirementForm = () => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [bhkType, setBhkType] = useState('');
  const [kitchenLayout, setKitchenLayout] = useState('');
  const [carpetArea, setCarpetArea] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const db = getFirestore();

    try {
      // Add the form data to Firestore
      const docRef = await addDoc(collection(db, 'requirement'), {
        name,
        phoneNumber,
        address,
        bhkType,
        kitchenLayout,
        carpetArea,
        timestamp: serverTimestamp(),
      });

      console.log('Document written with ID: ', docRef.id);

      // Reset the form fields after submission
      setName('');
      setPhoneNumber('');
      setAddress('');
      setBhkType('');
      setKitchenLayout('');
      setCarpetArea('');
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000); // Hide success message after 3 seconds
    } catch (error) {
      console.error('Error adding document: ', error);
      setLoading(false);
    }
  };

  return (
    <div className="requirement-form-container">
      <h2>User Requirement Form</h2>
      <hr />
      <br />
      <form onSubmit={handleSubmit}>
        <div className='ReqFormCont'>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number:</label>
            <input
              type="tel"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="bhkType">BHK Type:</label>
            <select
              id="bhkType"
              value={bhkType}
              onChange={(e) => setBhkType(e.target.value)}
              required
            >
              <option value="">Select BHK Type</option>
              <option value="1BHK">1BHK</option>
              <option value="2BHK">2BHK</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="kitchenLayout">Kitchen Layout:</label>
            <select
              id="kitchenLayout"
              value={kitchenLayout}
              onChange={(e) => setKitchenLayout(e.target.value)}
              required
            >
              <option value="">Select Kitchen Layout</option>
              <option value="L Shaped">L Shaped</option>
              <option value="U Shaped">U Shaped</option>
              <option value="Straight">Straight</option>
              <option value="Parallel">Parallel</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="carpetArea">Carpet Area:</label>
            <input
              type="number"
              id="carpetArea"
              value={carpetArea}
              onChange={(e) => setCarpetArea(e.target.value)}
              required
            />
          </div>
        </div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <button className='reqFormSubmit' >Submit</button>
        )}
      </form>
      {success && (
        <div className="modal">
          <div className="modal-content">
            <img className='tickImg' src={GreenTick}/>
            <span className="close" onClick={() => setSuccess(false)}>&times;</span>
            <p>Form submitted successfully!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequirementForm;
